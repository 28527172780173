import CryptoJS from "crypto-js";
import { functions } from "../firebase";

export const getKey = () => {
  let readFileFromStorage = functions.httpsCallable("readFileFromStorage");
  return new Promise((resolve, reject) => {
    readFileFromStorage({ fileName: "encryption-key.json" })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};
export const encryptEmail = (email, secret) => {
  let b64 = CryptoJS.AES.encrypt(email, secret).toString();
  return b64;
};

export const decryptEmail = (email) => {
  return new Promise((resolve, reject) => {
    getKey().then((secret) => {
      let bytes;
      let decrypted;
      try {
        bytes = CryptoJS.AES.decrypt(email, secret);
        decrypted = bytes.toString(CryptoJS.enc.Utf8);
        resolve(decrypted);
      } catch (err) {
        decrypted = err;
        reject(decrypted);
      }
    });
  });
};

export const exportFile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_self");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isChrome = () => {
  let userAgent = navigator.userAgent;
  let isChrome = false;
  if (userAgent.match(/chrome|chromium|crios/i) !== null) isChrome = true;
  return isChrome;
};
