import React from "react";
import { withRouter } from "react-router-dom";
import kiwiGraphic from "../assets/images/coming_soon_new.png";
import SubscribeForm from "../ui-components/SubscribeForm.js";

class ComingSoonPage extends React.Component {
  render() {
    return (
      <div id="coming-soon-page">
        <div id="coming-soon-title">
          <h2>We are coming soon!</h2>
        </div>
        <div id="under-construction-image">
          <img src={kiwiGraphic} alt="kiwi"></img>
        </div>
        <SubscribeForm text="Notify me"></SubscribeForm>
        <div id="back-to-home">
          <a className="link link--arrowed" href="/">
            <svg
              className="arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="90"
              height="23"
              viewBox="0 0 14 14"
            >
              <g
                fill="none"
                stroke="#f6c741"
                strokeWidth="1.5"
                strokeLinejoin="round"
                strokeMiterlimit="10"
              >
                <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </g>
            </svg>
            <br />
            Home
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(ComingSoonPage);
