import React, { useState, useRef, useEffect } from "react";
import logo from "../assets/icons/Kiwi-makes-logo.png";
import NavbarDropdown from "./NavbarDropdown";
import { compatibleFormatsForExport } from "../config/r3f";
const Navbar = (props) => {
  const expBtnRef = useRef(null);
  const shareBtnRef = useRef(null);
  const [expFormats, setExpFormats] = useState([]);
  const [navbarSelection, setNavbarSelection] = useState();
  const [showSpinner, setShowSpinner] = useState(false);

  const exportScreenshot = (screenshot) => {
    props.onExportScreenshot(screenshot);
  };

  useEffect(() => {
    if (props.file)
      setExpFormats(compatibleFormatsForExport[props.file.format]);
  }, [props.file]);

  const showNavOption = (id, ref, title, dropdownId, options, marginLeft) => {
    return (
      <>
        <button
          id={id}
          ref={ref}
          className={"navbar-button " + props.inactiveButtonClass}
          onClick={(e) => {
            props.onShowPanel(undefined);
            if (props.inactiveButtonClass !== "inactive")
              setNavbarSelection(e.target.id);
            if (navbarSelection === id) {
              setNavbarSelection();
              ref.current.blur();
              ref.current.classList.add("deselected");
            }
          }}
          onMouseOver={() => {
            if (ref.current.classList.contains("deselected")) {
              ref.current.classList.remove("deselected");
            }
          }}
          onBlur={() => {
            setNavbarSelection();
          }}
        >
          {title}
        </button>
        {navbarSelection === id && (
          <NavbarDropdown
            file={props.file}
            action={dropdownId}
            options={options}
            marginLeft={marginLeft}
            onExportScreenshot={(screenshot) => exportScreenshot(screenshot)}
            onShowSpinner={(trigger) => {
              setShowSpinner(trigger);
            }}
            onShowPanel={(panelType) => {
              props.onShowPanel(panelType);
            }}
          />
        )}
      </>
    );
  };

  return (
    <div id="navbar">
      <img src={logo} alt="Kiwi" onClick={() => props.history.push("/")}></img>
      <button
        id="navbar-new"
        className={"navbar-button " + props.inactiveButtonClass}
        onClick={(e) => {
          props.onNew();
          props.history.push("/viewer");
          setNavbarSelection(e.target.id);
        }}
      >
        New
      </button>
      {showNavOption(
        "navbar-share",
        shareBtnRef,
        "Share",
        "navbar-dropdown-share",
        ["URL", "IFRAME"],
        "180px"
      )}
      {showNavOption(
        "navbar-export",
        expBtnRef,
        "Export",
        "navbar-dropdown-export",
        expFormats,
        "270px"
      )}
      {showSpinner === true && <div className="spinner"></div>}
    </div>
  );
};
export default Navbar;
