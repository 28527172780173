import { db } from "../../firebase";
import { encryptEmail, getKey } from "../helpers";
export const saveDBemail = (email) => {
  return new Promise((resolve, reject) => {
    db.collection("subscribers")
      .doc(email)
      .set({ confirmed: false })
      .then(() => {
        resolve("success");
        sendEmail(email);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendEmail = (email) => {
  return new Promise((resolve, reject) => {
    getKey().then((key) => {
      db.collection("mail")
        .doc()
        .set({
          from: "Kiwi Makes <hello@kiwimakes.com>",
          to: email,
          message: {
            subject: "Hello from Kiwi!",
            html:
              // eslint-disable-next-line
              "<!DOCTYPE html>\
          <html lang='en' xmlns='http://www.w3.org/1999/xhtml' xmlns:o='urn:schemas-microsoft-com:office:office'>\
          <head>\
              <meta charset='UTF-8'>\
             <meta name='viewport' content='width=device-width,initial-scale=1'>\
            <meta name='x-apple-disable-message-reformatting'>\
            <title></title>\
            <!--[if mso]>\
          <noscript>\
          <xml>\
          <o:OfficeDocumentSettings>\
          <o:PixelsPerInch>96</o:PixelsPerInch>\
          </o:OfficeDocumentSettings>\
          </xml>\
          </noscript>\
          <![endif]-->\
            <style>\
              table, td, div, h1, p {font-family: Open sans, sans-serif;}\
              table, td {border:0}\
              .paragraph {\
                padding:0 20% 0 20%;\
              }\
              .second-paragraph {\
                padding:2% 15% 4% 15%;\
              }\
              .logo{\
                width:70%\
              }\
              .learn-more-btn{\
                font-size:x-large;\
                width:200px;\
                line-height:0.7em;\
              }\
              .third-paragraph{\
                padding:2% 0 0 0;\
              }\
              .fourth-paragraph{\
                padding:0 0 8% 0;\
              }\
              @media only screen and (max-width: 768px){\
                  .paragraph{\
                    padding:0;\
                  }\
                  .second-paragraph{\
                    padding:2% 0 4% 0;\
                  }\
                  .logo{\
                    width:100%\
                  }\
                  .learn-more-btn{\
                    font-size:large;\
                    width:120px;\
                    line-height:0.5em;\
                  }\
                  .third-paragraph{\
                    padding:10% 0 0 0;\
                  }\
              }\
            </style>\
          </head>\
          <body style='margin:0;padding:0;'>\
    <table role='presentation' style='width:100%;border-collapse:collapse;border:0;border-spacing:0;table-layout: fixed;'>\
		<tr>\
			<td align='center' style='margin:0; font-weight:700; font-size:xxx-large;line-height:2.5em;background:#facb73;'>\
				Welcome to Kiwi Makes\
			</td>\
    </tr>\
    <tr>\
    <tr>\
      <td align='center' >\
        <img src='https://firebasestorage.googleapis.com/v0/b/kiwi-makes-68b7b.appspot.com/o/icons%2Ffestive-kiwi.png?alt=media' alt='' class='logo' style='pointer-events:none;height:auto;display:block;' />\
      </td>\
    <tr>\
    <tr>\
      <td align='center' class='paragraph' style=' margin:0; font-weight:400; font-size:medium;color:#737373'>\
        <b>Congratulations</b>\
          οn signing up for our Kiwi Makes Beta phase. We are really happy to have\
          you on board!\
      </td>\
    </tr>\
    <tr>\
      <td align='center' class='second-paragraph' style='font-weight:400; font-size:medium;color:#737373'>\
        You can now sit back, relax and we'll get back to you every time we\
        release new and exciting features. In the meantime, you can check what\
        our team is cooking for you.\
      </td>\
    </tr>\
    <tr>\
      <td>\
        <table role='presentation' style='width:100%;border-collapse:collapse;border:0;border-spacing:0;table-layout: fixed;'>\
          <tr>\
            <td></td>\
            <td align='center' class='learn-more-btn' style='font-weight:700; background:#F6C221;color:#ffffff; border-radius: 100px 100px 100px 100px;\
            padding: 0.7em 0.7em;'>\
              <a style='\
                text-decoration: inherit;\
                color: inherit;'\
                href='https://kiwimakes.com'>Learn more</a>\
            </td>\
            <td></td>\
          </tr>\
        </table>\
      </td>\
    </tr>\
    <tr>\
      <td align='center' class='third-paragraph' style='font-weight:400; font-size:medium;color:#737373'>\
        <b>Have more questions?</b>\
      </td>\
    </tr>\
    <tr>\
      <td align='center' class = 'fourth-paragraph' style='font-weight:400; font-size:medium;color:#737373'>\
        You can contact us at <a href='mailto: hello@kiwimakes.com'>hello@kiwimakes.com</a>\
      </td>\
    </tr>\
    <tr>\
			<td style='padding:2%;background:#facb73;'>\
			</td>\
    </tr>\
    <tr>\
      <td align='center' style='padding:1%; font-weight:400; font-size:medium;color:#737373'>\
        <b>Kiwi Makes</b>\
      <br>\
        3D printing tools more <b style='color:#6fc6aa'>accessible</b> than ever\
      </td>\
    </tr>\
    <tr align='center'>\
      <td>\
        <table role='presentation' style='width:100%;border-collapse:collapse;border:0;border-spacing:0;table-layout: fixed;'>\
          <tr>\
            <td></td>\
            <td></td>\
            <td align='right'>\
              <a href=><img src='https://firebasestorage.googleapis.com/v0/b/kiwi-makes-68b7b.appspot.com/o/icons%2Flinkedin.png?alt=media' width='35' height='35' alt='' style='display:block;'/></a>\
            </td>\
            <td align='center'>\
              <a href='https://www.facebook.com/kiwi.mks/?ref=page_internal'><img src='https://firebasestorage.googleapis.com/v0/b/kiwi-makes-68b7b.appspot.com/o/icons%2Ffacebook.png?alt=media'width='35' height='35' alt='' style='display:block;' /></a>\
            </td>\
            <td align='left' >\
              <a href='https://www.instagram.com/kiwi.makes/?hl=en'><img src='https://firebasestorage.googleapis.com/v0/b/kiwi-makes-68b7b.appspot.com/o/icons%2Finstagram.png?alt=media' width='35' height='35' alt='' style='display:block;' /></a>\
            </td>\
            <td></td>\
            <td></td>\
          </tr>\
        </table>\
      </td>\
    </tr>\
    <hr align='left' width='35%' style='color:#737373;margin-top:5%'>\
    <tr>\
      <td style='font-weight:400; font-size:medium;color:#737373'>\
        If you subscribed in error or no longer want to hear from us you can always <a href='http://kiwimakes.com/unsubscribe/" +
              encodeURIComponent(encryptEmail(email, key)) +
              // eslint-disable-next-line
              "'>unsubscribe</a>.\
    </tr>\
	</table>\
</body>\
</html>",
          },
        })
        .then(() => {
          resolve("success");
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export const saveDBobject = (fileName, fileID, dataType, timestamp) => {
  let record = db.collection("objects").doc(fileID);
  return new Promise((resolve, reject) => {
    record
      .set({
        fileName: fileName,
        dataType: dataType,
        uploadedOn: timestamp,
      })
      .then(function () {
        //value successfully created
        resolve();
      })
      .catch(function (error) {
        //error
        reject(error);
      });
  });
};

export const deleteDBsubscriber = (subscriber) => {
  db.collection("subscribers")
    .doc(subscriber)
    .delete()
    .then(() => {
      // console.log("Document successfully deleted!");
    })
    .catch((error) => {
      // console.error("Error removing document: ", error);
    });
};

export const updateDBsubscriber = (subscriber, key, value) => {
  db.collection("subscribers")
    .doc(subscriber)
    .update({ [key]: value })
    .then(() => {
      // console.log("Document successfully updated!");
    })
    .catch((error) => {
      // console.error("Error updating document: ", error);
    });
};

export const getDBfile = (fileID) => {
  var docRef = db.collection("objects").doc(fileID);
  return new Promise((resolve, reject) => {
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          reject("File not found");
        }
      })
      .catch((error) => {
        reject("Error getting file");
      });
  });
};
