import React from "react";
import mouseRightIcon from "../assets/icons/mouse_right.png";
import mouseMiddleIcon from "../assets/icons/mouse_middle.png";
import mouseWheelIcon from "../assets/icons/mouse_wheel.png";

class ControlsLegendComponent extends React.Component {
  render() {
    return (
      <>
        <div className={this.props.className}>
          <div className="control-row">
            <div>
              <img src={mouseRightIcon} alt="Right: " />
            </div>
            <div className="legend-text">Rotate</div>
          </div>
          <div className="control-row">
            <div>
              <img src={mouseMiddleIcon} alt="Middle: " />
            </div>
            <div className="legend-text">Move</div>
          </div>
          <div className="control-row">
            <div>
              <img src={mouseWheelIcon} alt="Wheel: " />
            </div>
            <div className="legend-text">Zoom</div>
          </div>
        </div>
      </>
    );
  }
}

export default ControlsLegendComponent;
