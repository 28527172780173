import React from "react";
import { exportFiles } from "../js/microservice-requests";
import { downloadFromStorage, checkIfFileExists } from "../js/firebase/storage";
import { exportFile } from "../js/helpers";
import { analytics } from "../firebase";

const NavbarDropdown = (props) => {
  const handleDropdownClick = (optionClicked) => {
    if (props.action === "navbar-dropdown-export") {
      props.onShowSpinner(true);
      let desiredFormat = optionClicked;
      analytics.logEvent("export_extension", {
        export_extension: desiredFormat,
      });
      if (desiredFormat !== "png") {
        let currentFile = props.file.id + "." + props.file.format;
        let convertFile = props.file.id + "." + desiredFormat;
        //check if file with desired extension exists in storage
        downloadFromStorage(`objects/${props.file.id}/${convertFile}`)
          //file with desired extension already exists
          //there is no need for a backend request
          .then((url) => {
            exportFile(url);
            props.onShowSpinner(false);
          })
          //file with desired extension does not exists
          //call export endpoint
          .catch(async (err) => {
            exportFiles(currentFile, [desiredFormat]).then(async (response) => {
              let fileIsUploaded = false;
              do {
                fileIsUploaded = checkIfFileExists(
                  `objects/${props.file.id}/${currentFile}`
                );
              } while (!fileIsUploaded);
              downloadFromStorage(`objects/${props.file.id}/${convertFile}`)
                //file with desired extension already exists
                //there is no need for a backend request
                .then((url) => {
                  exportFile(url);
                  props.onShowSpinner(false);
                });
            });
          });
      } else {
        props.onExportScreenshot(props.file.name.split(".")[0]); //TODO?!?!?
        props.onShowSpinner(false);
      }
    } else if (props.action === "navbar-dropdown-share") {
      props.onShowPanel(optionClicked);
    }
  };

  const dropdownList = props.options.map((option) => {
    return (
      <div key={props.action + "-" + option.toLowerCase()}>
        <button
          className={"navbar-dropdown-button"}
          onMouseDown={() => {
            handleDropdownClick(option.toLowerCase());
          }}
        >
          {option}
        </button>
      </div>
    );
  });

  return (
    <div
      className="navbar-dropdown"
      id={props.action}
      style={{ marginLeft: props.marginLeft }}
    >
      {dropdownList}
    </div>
  );
};
export default NavbarDropdown;
