import { functions } from "../firebase";

export const convertSTEP = (fileName) => {
  let convertFunc = functions.httpsCallable("convert", { timeout: 180000 });
  return new Promise((resolve, reject) => {
    convertFunc({
      filename: fileName,
      assembly: 0,
    }).then((response) => {
      //use the response for the annotation
      if (response.data.statusCode === 200) {
        resolve("success");
      } else {
        reject("Error on file processing"); // convert endpoint internal server error
      }
    });
  });
};

export const exportFiles = (fileName, outputFormats) => {
  let exportFunc = functions.httpsCallable("export");
  return new Promise((resolve, reject) => {
    exportFunc({
      filename: fileName,
      output_formats: outputFormats,
    }).then((response) => {
      if (response.data.statusCode === 200) {
        resolve("success");
      } else reject(undefined);
    });
  });
};
