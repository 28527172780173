import { React, useState, useEffect } from "react";
const NotificationComponent = (props) => {
  const [hide, setHide] = useState("");

  useEffect(() => {
    if (props.info && props.info.message !== undefined) {
      const timeId = setTimeout(() => {
        // After 30 seconds set the show value to false
        setHide("fadeout");
      }, 3000);

      return () => {
        clearTimeout(timeId);
        setHide("");
      };
    }
  }, [props.info]);

  useEffect(() => {
    if (hide === "fadeout") {
      const timeId = setTimeout(() => {
        // After 20 reset error on parent component
        props.onHideInfo();
      }, 2000);

      return () => {
        clearTimeout(timeId);
        setHide("");
      };
    }
    // eslint-disable-next-line
  }, [hide]);
  return (
    <>
      {props.info !== undefined && (
        <div className={"panel " + props.info.style + " " + hide}>
          <p>{props.info.message}</p>
          {props.info.icon && props.info.icon}
        </div>
      )}
    </>
  );
};
export default NotificationComponent;
