import React from "react";
import { saveDBobject } from "../js/firebase/database";
import { uploadedFileTypes } from "../config/r3f";
import loadingAnimation from "../assets/animations/loading-kiwi.gif";
import { uploadToStorage } from "../js/firebase/storage";
import { analytics } from "../firebase";
export default class FileUploadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      loadingTextIdx: 0,
    };
    this.inputFileRef = React.createRef();
  }

  importFile = (file) => {
    let uploadedFileTypesArray = Object.values(uploadedFileTypes).flat(1);
    let url = URL.createObjectURL(file);
    if (
      uploadedFileTypesArray.some((extension) => file.name.endsWith(extension))
    ) {
      let bufferReader = new FileReader();
      bufferReader.readAsArrayBuffer(file);
      bufferReader.addEventListener(
        "load",
        () => {
          const fileExtension = file.name.split(".").pop();
          analytics.logEvent("upload_extension", {
            upload_extension: fileExtension,
          });
          const generalFormat = Object.keys(uploadedFileTypes).find((key) => {
            return uploadedFileTypes[key].includes(`.${fileExtension}`);
          });

          // Create the file metadata
          let metadata = {
            contentType: generalFormat,
            contentDisposition: `attachment;filename=${file.name}`,
          };
          let timestamp = new Date(Date.now()).toLocaleString("sv-SE");
          let fileID =
            timestamp.replace(" ", "").replaceAll("-", "").replaceAll(":", "") +
            Math.random().toString(36).slice(2);
          if (generalFormat !== "step")
            //display object with no interference with google storage
            this.props.onFileUploaded(fileID, file.name, generalFormat, url);
          else {
            this.setState({ loader: true, loadingTextIdx: 0 });
          }
          // Upload file and metadata to the storage
          uploadToStorage(
            `objects/${fileID}`,
            fileID + "." + generalFormat,
            bufferReader.result,
            metadata
          )
            .then((data) => {
              if (generalFormat === "step")
                this.props.onFileUploaded(
                  fileID,
                  file.name,
                  generalFormat,
                  url
                );
              saveDBobject(file.name, fileID, generalFormat, timestamp).then(
                () => {
                  this.setState((prevState) => ({
                    loadingTextIdx: prevState.loadingTextIdx + 1,
                  }));
                  //   let objRef = storage.ref(`objects/${partId}/${partId}.obj`);
                  //   // Get the download URL
                  //   objRef.getDownloadURL().then((url) => {
                  //     this.props.onCreateObj(url, filenameWithId, partId);
                  //   });
                  // });
                }
              );
            })
            .catch((err) => {
              this.props.onFileUploadError(err);
            });
        },
        false
      );
    } else {
      //on load wrong extension
      //on drop wrong extension
      this.props.onFileUploadError("Wrong file type");
    }
  };

  handleDrop = (e) => {
    //prevent the default behavior of the browser when something is dropped
    e.preventDefault();
    //stop the event from being propagated through parent and child elements
    e.stopPropagation();
    if (e.dataTransfer.files.length !== 0) {
      this.importFile(e.dataTransfer.files[0]);
    }
  };

  handleDrag = (e) => {
    //prevent the default behavior of the browser when something is dragged in
    e.preventDefault();
    //stop the event from being propagated through parent and child elements
    e.stopPropagation();
  };

  componentDidUpdate(nextProps) {
    if (nextProps.loadedFile) {
      this.importFile(nextProps.loadedFile);
    }
    if (nextProps.error !== undefined && this.state.loader !== false)
      this.setState({ loader: false });
  }

  componentDidMount() {
    if (this.props.id) {
      this.setState({ loader: true });
    }
  }
  render() {
    let uploadFileForm = (
      <>
        <div
          onDrop={(e) => this.handleDrop(e)}
          onDragOver={(e) => this.handleDrag(e)}
        >
          <div className="file-upload-panel">
            <div className="panel-label">Drag&Drop file here</div>
            <div className="sm-panel-label">or </div>
            <input
              type="file"
              ref={this.inputFileRef}
              id="file-upload"
              accept={Object.values(uploadedFileTypes).flat(1).toString()}
              onChange={(e) => {
                let file = e.target.files[0];
                this.importFile(file);
              }}
              onClick={(event) => {
                event.target.value = "";
              }}
              hidden
            />
            <button
              className=" accent-button"
              onClick={() => {
                analytics.logEvent("upload_button_clicked");
                this.inputFileRef.current.click();
              }}
            >
              Upload 3D file
            </button>
          </div>
        </div>
      </>
    );

    const loadingTextArray = ["uploading", "processing"];
    let loadingTextThatChanges =
      loadingTextArray[this.state.loadingTextIdx % loadingTextArray.length];

    let loader = (
      <>
        <div className="loader-container">
          <img src={loadingAnimation} alt="loading..." />
          {this.props.id ? (
            <h3 className="loader">loading</h3>
          ) : (
            <h3 className="loader">{loadingTextThatChanges}</h3>
          )}
        </div>
      </>
    );
    return (
      <div className="upload-container">
        {this.state.loader === true
          ? this.props.error === undefined && loader
          : uploadFileForm}
      </div>
    );
  }
}
