import { React, useState, useEffect } from "react";

const LoaderCheckmark = (props) => {
  const [confirmSubscribeClass, setConfirmSubscribeClass] =
    useState("circle-loader");

  useEffect(() => {
    setTimeout(() => {
      setConfirmSubscribeClass("circle-loader load-complete");
    }, 3000);
  }, []);

  useEffect(() => {
    if (confirmSubscribeClass === "circle-loader load-complete") {
      setTimeout(() => {
        props.onConfirmSubscribe();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [confirmSubscribeClass]);
  return (
    <>
      {confirmSubscribeClass !== "circle-loader load-complete" ? (
        <>
          <span className={confirmSubscribeClass}></span>
        </>
      ) : (
        <>
          <span className={confirmSubscribeClass}>
            <div className="checkmark draw" style={{ display: "block" }}></div>
          </span>
        </>
      )}
    </>
  );
};
export default LoaderCheckmark;
