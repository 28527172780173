import React from "react";
import logo from "../assets/icons/Kiwi-makes-logo.png";
import printerGraphicImage from "../assets/images/Printer-graphic.png";
import europeanIcon from "../assets/icons/european.png";
import questionIcon from "../assets/icons/question.png";
import ThreeDPrinterIcon from "../assets/icons/3d-printer.png";
import lineGraphIcon from "../assets/icons/line-graph.png";
import simulationIcon from "../assets/icons/simulation.png";
import screenshotShareImage from "../assets/images/Screenshot-share.png";
import screenshotExportImage from "../assets/images/Screenshot-export.png";
import screenshotVisualiseImage from "../assets/images/Screenshot-visualise.png";
import { ReactComponent as YoutubeIcon } from "../assets/icons/youtube.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { withRouter } from "react-router-dom";
import SubscribeForm from "../ui-components/SubscribeForm.js";
import auth from "../js/auth";
import { analytics } from "../firebase";
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", confirmSubscribe: false };
  }
  isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email) === true;
  };

  saveEmail = (email) => {
    this.setState({ email: email });
  };
  render() {
    return (
      <>
        <div className="brand">
          <img className="logo" src={logo} alt="logo" />
          <h2>Kiwi Makes</h2>
        </div>
        <main>
          <hr />
          <section id="hero">
            <header>
              <h1>
                3D printing tools <br /> more{" "}
                <span className="marked">accessible</span>
                <br /> than ever
              </h1>
              <div id="subtitle">
                <h4>
                  Work with .step and .stl files <br /> No download or sign-in
                  required
                </h4>
                <button
                  id="upload-file-header"
                  className="three-d"
                  onClick={() => {
                    analytics.logEvent("landing_upload_top_click");
                    auth.anomymousLogin(() => {
                      this.props.history.push("/viewer");
                    });
                  }}
                >
                  Upload your file
                </button>
              </div>
            </header>
            <img
              id="hero-visual"
              src={printerGraphicImage}
              alt="printer graphic"
            />
          </section>
          <section id="partners">
            <h4>Trusted by</h4>
            <img src={europeanIcon} alt="european" id="european" />
          </section>
          <section id="features-list">
            <div className="feature" id="feature-visualise">
              <div className="feature-image">
                <img src={screenshotVisualiseImage} alt="visualise" />
              </div>
              <div className="feature-description">
                <h3>Visualise</h3>
                <p>
                  Inspect your models easily with our 3D Viewer. Drag and drop
                  .stl, .obj, .gcode and even the popular b-rep .step format and
                  see your 3D models take shape.
                </p>
              </div>
            </div>
            <div className="feature" id="feature-export">
              <div className="feature-image">
                <img src={screenshotExportImage} alt="export" />
              </div>
              <div className="feature-description">
                <h3>Export</h3>
                <p>
                  Convert and export your files in various file formats. STEP to
                  STL, OBJ to GLTF, and many more. For the first time, you can
                  even export your G.Code files in a 3D format, so you may
                  capture that funky 3D printing texture in your product
                  renders.
                </p>
              </div>
            </div>
            <div className="feature" id="feature-share">
              <div className="feature-image">
                <img src={screenshotShareImage} alt="share" />
              </div>
              <div className="feature-description">
                <h3>Share</h3>
                <p>
                  Easily communicate your designs through sharing URLs or even
                  embed them in your website via a convenient iframe tag.
                </p>
              </div>
            </div>
          </section>
          <section id="sneak-peek">
            <h2>
              Here's a sneak peek <br /> at what the team is working on!
            </h2>
            <div id="cards">
              <div className="card">
                <img src={questionIcon} alt="dss" />
                <h3>Decision Making</h3>
                <p>
                  We are able to recommend you the most suitable material and
                  process for your specific application.
                </p>
              </div>
              <div className="card">
                <img src={ThreeDPrinterIcon} alt="slicing" />
                <h3>Slicing</h3>
                <p>
                  We leverage the powerful Cura Engine to provide you with an
                  intuitive slicer running completely and seamlessly on the web.
                </p>
              </div>
              <div className="card">
                <img src={simulationIcon} alt="simulation" />
                <h3>Simulation</h3>
                <p>
                  We employ state-of-the-art Finite Element Analysis engines to
                  simulate the performance of your 3D prints.
                </p>
              </div>
              <div className="card">
                <img src={lineGraphIcon} alt="optimization" />
                <h3>Optimisation</h3>
                <p>
                  We use state of the art technologies to optimise the printing
                  parameters and part orientation for maximum performance.
                </p>
              </div>
            </div>
            <div id="beta">
              <h4>Apply for the beta</h4>
              <div id="subscribe-form">
                <SubscribeForm text="Subscribe"></SubscribeForm>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <div id="who-we-are">
            <div className="brand">
              <img className="logo" src={logo} alt="logo" />
              <h2>Kiwi Makes</h2>
            </div>
            <p>
              We are a research-driven team of young and ambitious
              professionals. Our team is comprised of passionate and talented
              engineers, software developers, and designers. We conduct
              state-of-the-art research on 3D Printing and Digital Manufacturing
              and our projects are funded by the European Commission.
            </p>
          </div>
          <div id="footer-buttons">
            <button
              id="upload-file-footer"
              className="three-d"
              onClick={() => {
                analytics.logEvent("landing_upload_footer_click");
                auth.anomymousLogin(() => {
                  this.props.history.push("/viewer");
                });
              }}
            >
              Upload your file
            </button>
            <div id="social">
              <button href="">hello@kiwimakes.com</button>
              <div id="social-buttons">
                <button className="social-button">
                  <YoutubeIcon />
                </button>
                <button
                  className="social-button"
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/kiwi.makes/?hl=en",
                      "_blank"
                    );
                  }}
                >
                  <InstagramIcon />
                </button>
                <button
                  className="social-button"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/kiwi.mks/?ref=page_internal",
                      "_blank"
                    );
                  }}
                >
                  <FacebookIcon />
                </button>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default withRouter(LandingPage);
