import React from "react";
import { Helmet } from "react-helmet";
import LandingPage from "./pages/LandingPage.js";

export default class App extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Kiwi Makes</title>
          <meta
            name="description"
            content="View, convert, download and share 3D models in STL, STEP, OBJ, GLB formats with Kiwi Makes online tools"
          />
          <meta
            name="keywords"
            content="online step viewer, online stl viewer, online 3d viewer, 3d printing tools, online glb viewer, online obj viewer"
          />
        </Helmet>
        <LandingPage />
      </>
    );
  }
}
