import React from "react";
// import placeholderImage from "../assets/images/object.jpeg";
import FileUploadComponent from "../ui-components/FileUploadComponent";
import ControlsLegendComponent from "../ui-components/ControlsLegendComponent";
import Navbar from "../ui-components/Navbar";
import ViewerWindow from "../3d-components/R3fViewer";
import { convertSTEP } from "../js/microservice-requests.js";
import { Canvas } from "@react-three/fiber";
import { downloadFromStorage } from "../js/firebase/storage";
import NotificationComponent from "../ui-components/InfoComponent";
import PanelComponent from "../ui-components/PanelComponent";
import { getDBfile } from "../js/firebase/database";
import loadingAnimation from "../assets/animations/loading-kiwi.gif";
import { isChrome } from "../js/helpers.js";

import { analytics } from "../firebase";
import auth from "../js/auth";

export default class Viewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: undefined,
      screenshot: undefined,
      info: undefined,
      panel: undefined,
      showLoader: true,
      showLegendCount: 0,
      showLegend: false,
    };
  }

  componentDidMount() {
    auth.anomymousLogin(() => {
      if (this.props.match.params.id) {
        this.displaySharedObject(this.props.match.params.id);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.info !== this.state.info &&
      this.state.info !== undefined &&
      this.state.info.style === "alert"
    ) {
      console.log(this.state.info);
      analytics.logEvent("alert_message", {
        alert_message:
          this.state.info.message +
          "_" +
          (this.state.file ? this.state.file.format : undefined),
      });
    }
  }
  handleDrop = (e) => {
    //prevent the default behavior of the browser when something is dropped
    e.preventDefault();
    //stop the event from being propagated through parent and child elements
    e.stopPropagation();
    if (e.dataTransfer.files.length !== 0) {
      this.setState((prevState) => {
        return {
          ...prevState.file,
          name: e.dataTransfer.files[0],
        };
      });
    }
  };

  handleDrag = (e) => {
    //prevent the default behavior of the browser when something is dragged in
    e.preventDefault();
    //stop the event from being propagated through parent and child elements
    e.stopPropagation();
  };

  displaySharedObject = (fileID) => {
    getDBfile(fileID)
      .then((data) => {
        let displayType = data.dataType;
        if (data.dataType === "step") {
          displayType = "stl";
        }
        this.displayFromStorage(
          fileID,
          data.fileName,
          displayType,
          data.dataType
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ info: { message: err, style: "alert" } });
      });
  };
  displayFromStorage = (fileID, fileName, dataType, format) => {
    downloadFromStorage(`objects/${fileID}/${fileID}.${dataType}`)
      .then((url) => {
        this.setState({
          file: {
            id: fileID,
            name: fileName,
            format: format,
            url: url,
          },
        });
      })
      .catch((err) => {
        this.setState({
          info: { message: err, style: "alert" },
          file: undefined,
        });
      });
  };

  displayObject = (fileID, fileName, format, url) => {
    if (format === "step") {
      convertSTEP(`${fileID}.step`)
        .then((response) => {
          if (response === "success") {
            this.displayFromStorage(fileID, fileName, "stl", format);
          }
        })
        .catch((err) => {
          this.setState({
            info: { message: err, style: "alert" },
            file: undefined,
          });
        });
    } else {
      this.setState({
        file: {
          id: fileID,
          name: fileName,
          format: format,
          url: url,
        },
      });
    }
  };

  loader =
    isChrome() === true ? (
      <>
        <div className="upload-container">
          <div className="loader-container">
            <img src={loadingAnimation} alt="loading..." />
            <h3 className="loader">rendering</h3>
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="upload-container">
          <div className="loader-container">
            <h3 className="loader">rendering.</h3>
          </div>
        </div>
      </>
    );

  legendLogic = () => {
    this.setState({
      showLegendCount: this.state.showLegendCount + 1,
    });
    if (this.state.showLegendCount > 2) {
      this.setState({ showLegend: true });
      this.setState({ showLegendCount: 0 });

      const timer = setTimeout(() => {
        this.setState({ showLegend: false });
        this.setState({ showLegendCount: 0 });
      }, 20000);

      // Clear the timer on component unmount or button click
      return () => clearTimeout(timer);
    }
  };

  render() {
    return (
      <>
        <div className="wrapper">
          <Navbar
            onNew={() =>
              this.setState({
                file: undefined,
                panel: undefined,
                showLoader: true,
                showLegendCount: 0,
                showLegend: false,
              })
            }
            inactiveButtonClass={
              this.state.file === undefined ? "inactive" : ""
            }
            history={this.props.history}
            file={this.state.file}
            onExportScreenshot={(screenshot) =>
              this.setState({ screenshot: screenshot })
            }
            onShowPanel={(panelType) => this.setState({ panel: panelType })}
          ></Navbar>
          <div
            id="scene-container"
            onDrop={(e) => this.handleDrop(e)}
            onDragOver={(e) => this.handleDrag(e)}
          >
            {this.state.file === undefined ? (
              <FileUploadComponent
                id={this.props.match.params.id}
                error={
                  this.state.info &&
                  this.state.info.style === "alert" &&
                  this.state.info.message
                }
                loadedFile={
                  this.state.file !== undefined && this.state.file.name
                }
                onFileUploaded={(fileID, fileName, format, url) =>
                  this.displayObject(fileID, fileName, format, url)
                }
                onFileUploadError={(error) => {
                  this.setState({
                    info: { message: error, style: "alert" },
                    file: undefined,
                  });
                }}
              />
            ) : (
              <>
                <div>
                  <div id="canvas-3d">
                    <Canvas
                      gl={{ preserveDrawingBuffer: true }}
                      onClick={this.legendLogic}
                    >
                      <ViewerWindow
                        file={this.state.file}
                        screenshot={this.state.screenshot}
                        onExportScreenshot={() =>
                          this.setState({
                            screenshot: undefined,
                          })
                        }
                        onHideLoader={() => {
                          this.setState({
                            showLoader: false,
                          });
                        }}
                        onFileLoadError={(error) => {
                          this.setState({
                            info: { message: error, style: "alert" },
                            file: undefined,
                          });
                        }}
                      />
                    </Canvas>
                  </div>
                </div>
                {this.state.showLoader === true && this.loader}
              </>
            )}
          </div>
          <span>
            {this.state.file !== undefined ? this.state.file.name : ""}
          </span>
          {this.state.file !== undefined && (
            <ControlsLegendComponent
              className={
                this.state.showLegend === true
                  ? "controls-legend"
                  : "controls-legend hide"
              }
            />
          )}
          <NotificationComponent
            info={this.state.info}
            onHideInfo={() => this.setState({ info: undefined })}
          ></NotificationComponent>

          <PanelComponent
            id={this.state.file && this.state.file.id}
            panelType={this.state.panel}
            onHidePanel={() => this.setState({ panel: undefined })}
            onShowInfo={(info, icon) =>
              this.setState({
                info: { message: info, style: "info", icon: icon },
              })
            }
          ></PanelComponent>
        </div>
      </>
    );
  }
}
