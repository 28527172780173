import { React, useState, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { appUrl } from "../firebase";
const PanelComponent = (props) => {
  const [hide, setHide] = useState("");

  useEffect(() => {
    if (hide === "fadeout") {
      const timeId = setTimeout(() => {
        // After 20 reset error on parent component
        props.onHidePanel();
      }, 100);

      return () => {
        clearTimeout(timeId);
        setHide("");
      };
    }
    // eslint-disable-next-line
  }, [hide]);
  const urlPanel = (urlText) => {
    return (
      <div className={"panel nav " + hide}>
        <div className="close-icon" onClick={() => setHide("fadeout")}></div>
        <div className="url-line">
          <label>URL: </label>
          <div className="url-field-and-note">
            <input type="text" value={urlText} readOnly={true}></input>
            <div className="panel-note">
              The link will remain active for 17 hours.
            </div>
          </div>
          <button
            className="small-button"
            onClick={() => {
              navigator.clipboard.writeText(urlText);
              props.onShowInfo("Copied to clipboard", <AiFillCheckCircle />);
            }}
          >
            Copy
          </button>
        </div>
      </div>
    );
  };
  const iframePanel = () => {
    return (
      <div className={"panel nav " + hide}>
        <div className="close-icon" onClick={() => setHide("fadeout")}></div>
        <div className="url-line">
          <div className="panel-note">iframe : coming soon... </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setHide("");
  }, [props.panelType]);
  return (
    <>
      {props.panelType === "url"
        ? urlPanel(`${appUrl}/viewer/${props.id}`)
        : props.panelType === "iframe"
        ? iframePanel()
        : null}
    </>
  );
};
export default PanelComponent;
