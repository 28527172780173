import { app } from "../firebase.js";
class Auth {
  authenticated = null;
  anomymousLogin = async (cb) => {
    let error = null;
    await app
      .auth()
      .signInAnonymously()
      .then((cred) => {
        this.authenticated = cred.user.uid;
        cb();
      })
      .catch((loginError) => {
        error = loginError;
        // TODO: handle the error depending the error code
      });

    return error;
  };

  logout = (cb) => {
    app
      .auth()
      .signOut()
      .then(() => {
        this.authenticated = null;
        cb();
      });
  };

  getAuthenticatedUser = () => {
    return (
      this.authenticated ||
      (app.auth().currentUser && app.auth().currentUser.uid)
    );
  };
}
export default new Auth();
