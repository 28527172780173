import { storage } from "../../firebase";

export const uploadToStorage = (path, name, data, metadata) => {
  let storageRef = storage.ref(path); //create a reference
  return new Promise((resolve, reject) => {
    storageRef
      .child(name)
      .put(data, metadata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject("Error on file uploading");
      });
  });
};

export const downloadFromStorage = (path) => {
  let objRef = storage.ref(path);
  // Get the download URL
  return new Promise((resolve, reject) => {
    objRef
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((err) => {
        reject("Error on file downloading");
      });
  });
};

export const checkIfFileExists = async (filePath) => {
  let objRef = storage.ref(filePath);
  try {
    await objRef.getDownloadURL();
    return true;
  } catch (error) {
    return false;
  }
};
