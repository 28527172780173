import React, { useEffect, useState, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import {
  getObjectDimensions,
  getMaxDimension,
  getBoundingBox,
  getCenter,
} from "../js/three-utils";
import * as THREE from "three";
import { PerspectiveCamera, CameraControls, Edges } from "@react-three/drei";

function ViewerWindow(props) {
  const [object, setObject] = useState(undefined);
  const { gl } = useThree();

  const cameraRef = useRef();
  const edgesRef = useRef();

  const initializeCamera = (model) => {
    let maxDim = getMaxDimension(getObjectDimensions(getBoundingBox(model)));
    const homeCameraDirection = new THREE.Vector3(1, 1, 1);
    let newCameraPosition = homeCameraDirection.multiplyScalar(1.7 * maxDim);
    cameraRef.current.position.set(
      newCameraPosition.x,
      newCameraPosition.y,
      newCameraPosition.z
    );
    cameraRef.current.near = maxDim * 0.01;
    cameraRef.current.far = maxDim * 1000;
  };

  useEffect(() => {
    if (cameraRef.current !== undefined) {
      initializeCamera(object.data);
    }
  }, [object]);

  useEffect(() => {
    if (edgesRef.current !== undefined) {
      props.onHideLoader();
    }
  }, [edgesRef.current]);

  const checkForNaNPosition = (obj) => {
    if (obj.type !== "Group") {
      if (obj.geometry.attributes.position.array.includes(NaN)) {
        props.onFileLoadError("Error on file loading");
      }
    } else {
      // eslint-disable-next-line
      obj.children.map((child) => {
        if (child.geometry.attributes.position.array.includes(NaN)) {
          props.onFileLoadError("Error on file loading");
        }
      });
      // eslint-disable-next-line
    }
  };
  useEffect(() => {
    if (props.screenshot !== undefined) {
      const link = document.createElement("a");
      link.setAttribute("download", `${props.screenshot}.png`);
      link.setAttribute(
        "href",
        gl.domElement
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
      props.onExportScreenshot();
    }
    // eslint-disable-next-line
  }, [props.screenshot]);

  useEffect(() => {
    if (props.file && props.file.format) {
      if (props.file.format === "stl" || props.file.format === "step") {
        new STLLoader().load(
          props.file.url,
          function (geometry) {
            const material = new THREE.MeshPhysicalMaterial({
              color: "orange",
            });
            let stlMesh = new THREE.Mesh(geometry, material);
            checkForNaNPosition(stlMesh);
            setObject({
              ...object,
              position: getCenter(getBoundingBox(stlMesh)),
              data: stlMesh,
            });
          },
          function () {},
          function (e) {
            props.onFileLoadError("Error on file loading");
          }
        );
      }
      if (props.file.format === "glb" || props.file.format === "gltf") {
        new GLTFLoader().load(
          props.file.url,
          function (storageObject) {
            // checkForNaNPosition(storageObject.scene);
            setObject({
              ...object,
              position: getCenter(getBoundingBox(storageObject.scene)),
              data: storageObject.scene,
            });
          },
          function () {},
          function (e) {
            props.onFileLoadError("Error on file loading");
          }
        );
      }
      if (props.file.format === "obj") {
        new OBJLoader().load(
          props.file.url,
          function (storageObject) {
            const material = new THREE.MeshPhysicalMaterial({
              color: "orange",
            });

            const objMeshes = new THREE.Group();
            storageObject.traverse((child) => {
              if (child instanceof THREE.Mesh) {
                const mesh = new THREE.Mesh(child.geometry, material);
                objMeshes.add(mesh);
              }
            });

            checkForNaNPosition(storageObject);
            setObject({
              ...object,
              position: getCenter(getBoundingBox(storageObject)),
              data: objMeshes,
            });
          },
          function () {},
          function (e) {
            props.onFileLoadError("Error on file loading");
          }
        );
      }
    }

    // eslint-disable-next-line
  }, [props.file]);

  let DOMObject = object !== undefined && object.data !== undefined && (
    <>
      <ambientLight intensity={0.5} />
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        fov={30}
        up={[0, 0, 1]}
        position={[100, 100, 100]}
      >
        <directionalLight position={[-500, 200, 500]} intensity={3} />
        <directionalLight position={[600, -100, 500]} intensity={1} />
        <directionalLight position={[300, 300, -1000]} intensity={3} />
      </PerspectiveCamera>
      <mesh position={object.position}>
        <primitive object={object.data}>
          <Edges ref={edgesRef} threshold={45} color="orange" />
        </primitive>
      </mesh>

      <CameraControls
        mouseButtons={{
          left: 0,
          right: 1,
          middle: 2,
          wheel: 8,
        }}
      />
    </>
  );

  return <>{DOMObject}</>;
}

export default ViewerWindow;
