import React from "react";
import { withRouter } from "react-router-dom";
import { updateDBsubscriber } from "../js/firebase/database";
import { decryptEmail } from "../js/helpers";

class ConfirmEmailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }
  componentDidMount() {
    decryptEmail(decodeURIComponent(this.props.match.params.addr)).then(
      (email) => {
        updateDBsubscriber(email, "confirmed", true);
        this.setState({ email: email });
      }
    );
  }
  render() {
    return <div>confirm {this.state.email}</div>;
  }
}

export default withRouter(ConfirmEmailPage);
