import * as THREE from "three";

export const getBoundingBox = (object) => {
  // get bounding box of object - this will be used to setup controls and camera
  let boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  return boundingBox;
};

export const getObjectDimensions = (boundingBox) => {
  let size = new THREE.Vector3();
  size = boundingBox.getSize(size);
  return size;
};

export const getCenter = (boundingBox) => {
  let center = new THREE.Vector3();
  center = boundingBox.getCenter(center);
  return [-center.x, -center.y, -center.z];
};

export const getMaxDimension = (size) => {
  // get the max side of the bounding box (fits to width OR height as needed )
  let maxDim = Math.max(size.x, size.y, size.z);
  return maxDim;
};
