export const uploadedFileTypes = {
  step: [".stp", ".step", ".STP", ".STEP"],
  stl: [".stl", ".STL"],
  glb: [".glb", ".GLB"],
  gltf: [".gltf", ".GLTF"],
  obj: [".obj", ".OBJ"],
};

export const compatibleFormatsForExport = {
  step: ["STEP", "STL", "OBJ", "GLB", "PNG"],
  stl: ["STL", "OBJ", "GLB", "PNG"],
  obj: ["STL", "OBJ", "GLB", "PNG"],
  glb: ["STL", "OBJ", "GLB", "PNG"],
  gltf: ["STL", "OBJ", "GLB", "GLTF", "PNG"],
};
