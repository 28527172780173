import React, { useState } from "react";
import { saveDBemail } from "../js/firebase/database";
import LoaderCheckmark from "../widgets/LoaderCheckmark";
import { analytics } from "../firebase";

const SubscribeForm = (props) => {
  const [email, setEmail] = useState("");
  const [confirmSubscribe, setConfirmSubscribe] = useState(false);
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email) === true;
  };

  const saveEmail = (email) => {
    setEmail(email);
  };
  return (
    <div id="notify-block">
      <form id="notify-form">
        <input
          id="email"
          name="email"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          placeholder="Your email"
          value={email}
          onChange={(e) => saveEmail(e.target.value)}
        />
        <button
          type="submit"
          id="submit"
          className="input-button"
          onClick={(e) => {
            analytics.logEvent("subscribe_email_click", { email: props.text });
            e.preventDefault();
            var ele = document.getElementById("notify-form");
            var chk_status = ele.checkValidity();
            ele.reportValidity();
            if (chk_status) {
              if (isValidEmail(email)) {
                saveDBemail(email)
                  .then(() => {
                    setConfirmSubscribe(true);
                  })
                  .catch(() => {
                    setEmail("");
                  });
              }
            }
          }}
        >
          {confirmSubscribe ? (
            <LoaderCheckmark
              onConfirmSubscribe={() => {
                setEmail("");
                setConfirmSubscribe(false);
              }}
            />
          ) : (
            <div>{props.text}</div>
          )}
        </button>
      </form>
    </div>
  );
};

export default SubscribeForm;
