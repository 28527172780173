import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDvGsbJ8__Ccar4qF16XJp5HNsOpN1HRJ4",
  authDomain: "kiwi-makes-68b7b.firebaseapp.com",
  databaseURL:
    "https://kiwi-makes-68b7b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kiwi-makes-68b7b",
  storageBucket: "kiwi-makes-68b7b.appspot.com",
  messagingSenderId: "426135238705",
  appId: "1:426135238705:web:97b177b979eaa0be2bbd10",
  measurementId: "G-LYGL5T34GB",
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics(app);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const appUrl = "https://kiwimakes.com";
db.settings({ timestampInSnapshots: true });
